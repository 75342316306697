.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.EvColor{
  background-color: #e7501e;
}

.container-fluid {
  font-family: 'Raleway', serif !important;
}

.floating-btn {
  position: fixed;
  top: 5%;
  right: 4%;
  border-radius: 50% !important;
  width: 80px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  padding: 0;
}

.logo_wsp{
  height: 40px;
}

.modal-display-none {
  display: none;
}
